const hasAtLeastNumbers = (phrase, numbers = 6) => {
  const re = /\d/g
  const repeats = ((phrase || '').match(re) || []).length

  return repeats >= numbers
}

const hashHyphens = phrase => {
  const indexOfHyphen = phrase.indexOf('-')
  return indexOfHyphen !== -1
}

export default function exactSearch(phrase) {
  return hasAtLeastNumbers(phrase) || hashHyphens(phrase)
}

import React from 'react'
export default function StoresIcon(props) {
  return (
    <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00002 0.666687C2.77502 0.666687 0.166687 3.27502 0.166687 6.50002C0.166687 9.97502 3.85002 14.7667 5.36669 16.5917C5.70002 16.9917 6.30835 16.9917 6.64169 16.5917C8.15002 14.7667 11.8334 9.97502 11.8334 6.50002C11.8334 3.27502 9.22502 0.666687 6.00002 0.666687ZM6.00002 8.58335C4.85002 8.58335 3.91669 7.65002 3.91669 6.50002C3.91669 5.35002 4.85002 4.41669 6.00002 4.41669C7.15002 4.41669 8.08335 5.35002 8.08335 6.50002C8.08335 7.65002 7.15002 8.58335 6.00002 8.58335Z"
        fill="var(--navbar-text-color)"
      />
    </svg>
  )
}

import {useSuspenseQuery} from '@apollo/client'
import {
  GetCpgCategories_CachedQuery,
  GetCpgCategories_CachedQueryVariables
} from '@data/__generated__/types.main'
import useWebsiteId from '@hooks/useWebsiteId'
import gql from 'graphql-tag'

export default function useCPGCategories() {
  const websiteId = useWebsiteId()

  const {data} = useSuspenseQuery<
    GetCpgCategories_CachedQuery,
    GetCpgCategories_CachedQueryVariables
  >(
    gql`
      query getCPGCategories_cached($websiteId: ID!) {
        website(websiteId: $websiteId) {
          _id
          categories {
            _id
            name
            internalName
            index
            parent {
              _id
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-first',
      variables: {
        websiteId
      }
    }
  )

  return data?.website?.categories
}

import exactSearch from '@helpers/misc/exactSearch'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import React, {useEffect, useRef, useState} from 'react'

import LupaIcon from '../Icons/LupaIcon'

import getPathname from '@helpers/router/getPathname'
import styles from './styles.module.css'

export default function SearchBox(props) {
  const router = useRouter()
  const path = getPathname(router)
  const {layout} = props
  const searchParamString = layout.productsLayout === 'categories' ? 'q' : 'search'
  const [search, setSearch] = useState('')
  const searchInput = useRef()
  const history = useRouter()
  const searchParamKey = router.query[searchParamString]
  const searchParamEncoded: string = Array.isArray(searchParamKey)
    ? searchParamKey[0]
    : searchParamKey
  const searchInternalParamEncoded: string = Array.isArray(router.query.q)
    ? router.query.q[0]
    : router.query.q

  const searchParam = searchParamEncoded ? decodeURIComponent(searchParamEncoded) : null
  const searchInternalParam = searchInternalParamEncoded
    ? decodeURIComponent(searchInternalParamEncoded)
    : null

  const {t} = useTranslation('website', {keyPrefix: 'order'})

  const makeSearch = () => {
    if (search) {
      const url = `/pedir?${searchParamString}=${encodeURIComponent(search)}`
      // We use shallow routing to force the page to be remounted
      // Otherwise it won't get picked up by the useEffect at useUrlSearch
      history.push(url, url, {shallow: true})
    }
  }

  useEffect(() => {
    if (searchParam && !exactSearch(searchParam)) {
      setSearch(searchParam)
    } else {
      if (searchInternalParam && !exactSearch(searchInternalParam)) {
        setSearch(searchInternalParam)
      }
    }
  }, [searchParam, searchInternalParam])

  if (path === '/order' || path === '/pedir') {
    // Disable the search box on the order page since it has its own search bar
    return <div className={styles.searchBarBox} />
  }

  return (
    <div className={styles.searchBarBox}>
      <input
        placeholder={t('labelSearchProduct')}
        value={search}
        onChange={e => setSearch(e.target.value)}
        ref={searchInput}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            makeSearch()
          }
        }}
      />
      <div className={styles.searchButton} onClick={makeSearch}>
        <LupaIcon />
      </div>
    </div>
  )
}

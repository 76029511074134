import {
  GetMyOrderPreferencesQuery,
  GetMyOrderPreferencesQueryVariables
} from '@data/__generated__/types.main'
import appOrderPreferences from '@data/fragments/preferences/appOrderPreferences.main'
import isServerSide from '@helpers/misc/isServerSide'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export const getMyOrderPreferencesQuery = gql`
  query getMyOrderPreferences($websiteId: ID) {
    preferences: userPreferences(websiteId: $websiteId) {
      ...appOrderPreferences
    }
  }
  ${appOrderPreferences}
`

export const useMyOrderPreferences = (websiteId: string) => {
  return useApolloQuery<GetMyOrderPreferencesQuery, GetMyOrderPreferencesQueryVariables>({
    query: getMyOrderPreferencesQuery,
    variables: {websiteId},
    omit: isServerSide() || !websiteId,
    fetchPolicy: 'cache-first',
    partial: true
  })
}

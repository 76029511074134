import React from 'react'
import Link from 'next/link'

import ContactIcon from '../Icons/ContactIcon'
import HelpIcon from '../Icons/HelpIcon'
import StoresIcon from '../Icons/StoresIcon'

import styles from './styles.module.css'

export default function TopRibbon() {
  return (
    <div className={styles.topRibbon}>
      <ul>
        <li>
          <Link href="/ayuda">

            <div className={styles.icon}>
              <HelpIcon />
            </div>
            <div className={styles.icon}>Ayuda</div>

          </Link>
        </li>
        <li>
          <Link href="/contacto">

            <div className={styles.icon}>
              <ContactIcon />
            </div>
            <div className={styles.icon}>Contacto</div>

          </Link>
        </li>
        <li>
          <Link href="/locales">

            <div className={styles.icon}>
              <StoresIcon />
            </div>
            <div className={styles.icon}>Locales</div>

          </Link>
        </li>
      </ul>
    </div>
  );
}

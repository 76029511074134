import React from 'react'

export default function DeliveryIcon(props) {
  return (
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6667 5.16669C16.5167 5.16669 16.3667 5.19169 16.225 5.20835L14.5083 3.50002H15.8333C16.2917 3.50002 16.6667 3.12502 16.6667 2.66669V2.35002C16.6667 1.73335 16.0167 1.32502 15.4583 1.60835L13.5583 2.55835L11.4167 0.416687C11.2667 0.258354 11.05 0.166687 10.8333 0.166687H8.33333C7.875 0.166687 7.5 0.541687 7.5 1.00002C7.5 1.45835 7.875 1.83335 8.33333 1.83335H10.1417C10.3667 1.83335 10.575 1.92502 10.7333 2.07502L12.1583 3.50002H9.36667C9.23333 3.50002 9.10833 3.53335 8.99167 3.59169L6.375 4.90002C6.05833 5.05835 5.66667 5.00002 5.41667 4.74169L4.41667 3.74169C4.25833 3.59169 4.04167 3.50002 3.825 3.50002H0.833333C0.375 3.50002 0 3.87502 0 4.33335C0 4.79169 0.375 5.16669 0.833333 5.16669H3.33333C1.23333 5.16669 -0.408333 7.10002 0.0916667 9.28335C0.366667 10.4917 1.34167 11.4667 2.55 11.7417C4.73333 12.2417 6.66667 10.6 6.66667 8.50002L7.84167 9.67502C8.15833 9.99169 8.58333 10.1667 9.025 10.1667H9.86667C10.4667 10.1667 11.0167 9.85002 11.3167 9.32502L13.7417 5.08335L14.5833 5.92502C13.6417 6.68335 13.1167 7.93335 13.4333 9.30002C13.7167 10.5 14.6917 11.475 15.8917 11.75C18.0667 12.2417 20 10.5917 20 8.50002C20 6.65835 18.5083 5.16669 16.6667 5.16669ZM3.33333 10.1667C2.41667 10.1667 1.66667 9.41669 1.66667 8.50002C1.66667 7.58335 2.41667 6.83335 3.33333 6.83335C4.25 6.83335 5 7.58335 5 8.50002C5 9.41669 4.25 10.1667 3.33333 10.1667ZM16.6667 10.1667C15.75 10.1667 15 9.41669 15 8.50002C15 7.58335 15.75 6.83335 16.6667 6.83335C17.5833 6.83335 18.3333 7.58335 18.3333 8.50002C18.3333 9.41669 17.5833 10.1667 16.6667 10.1667Z"
        fill="var(--navbar-text-color)"
      />
    </svg>
  )
}

import React from 'react'

export default function HelpIcon(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00002 0.666687C4.40002 0.666687 0.666687 4.40002 0.666687 9.00002C0.666687 13.6 4.40002 17.3334 9.00002 17.3334C13.6 17.3334 17.3334 13.6 17.3334 9.00002C17.3334 4.40002 13.6 0.666687 9.00002 0.666687ZM9.83335 14.8334H8.16669V13.1667H9.83335V14.8334ZM11.5584 8.37502L10.8084 9.14169C10.2084 9.75002 9.83335 10.25 9.83335 11.5H8.16669V11.0834C8.16669 10.1667 8.54169 9.33335 9.14169 8.72502L10.175 7.67502C10.4834 7.37502 10.6667 6.95835 10.6667 6.50002C10.6667 5.58335 9.91669 4.83335 9.00002 4.83335C8.08335 4.83335 7.33335 5.58335 7.33335 6.50002H5.66669C5.66669 4.65835 7.15835 3.16669 9.00002 3.16669C10.8417 3.16669 12.3334 4.65835 12.3334 6.50002C12.3334 7.23335 12.0334 7.90002 11.5584 8.37502Z"
        fill="var(--navbar-text-color)"
      />
    </svg>
  )
}

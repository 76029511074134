import React, {useEffect, useRef, useState} from 'react'
import DeliveryWizard from '@components/DeliveryWizard'
import usePreferencesForAddressContext from '@components/DeliveryWizard/internals/hooks/usePreferencesForAddressContext.main'
import Tooltip from '@packages/justo-parts/lib/components/Tooltip'
import {useTranslation} from 'next-i18next'

import CaretIcon from '../Icons/CaretIcon'
import DeliveryIcon from '../Icons/DeliveryIcon'

import styles from './styles.module.css'

export default function Delivery() {
  const {preferences, loading} = usePreferencesForAddressContext()

  const {t} = useTranslation('website', {keyPrefix: 'order.cartMenuV2.optionsLabel'})
  const [typeOfDelivery, setTypeOfDelivery] = useState('')
  const [deliveryAddress, setdeliveryAddress] = useState('')
  const [open, setOpen] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    if (preferences.deliveryType === 'delivery') {
      setTypeOfDelivery('Delivery a:')
      if (preferences.address) {
        setdeliveryAddress(preferences.address.address)
      } else {
        setdeliveryAddress(t('address'))
      }
    }

    if (preferences.deliveryType === 'go') {
      setTypeOfDelivery('Retiro en:')

      if (!preferences.store) {
        setdeliveryAddress(t('store'))
      } else {
        setdeliveryAddress(t('storeGo', {store: preferences.store.name}))
      }
    }
  }, [preferences])

  const toggleOpen = () => {
    setOpen(e => !e)
  }

  if (loading || !preferences) return <div className={styles.loading} />

  const addressComponent = (
    <DeliveryWizard.AsModal
      isOpen={open}
      onClose={() => {
        setOpen(false)
      }}
      actionLocation="navbarcpg"
    />
  )

  return (
    <div className={styles.deliveryInfo} ref={ref}>
      <div className={styles.typeOfDelivery} onClick={toggleOpen}>
        <div className={styles.icon}>
          <DeliveryIcon />
        </div>
        <div className={styles.text}>{typeOfDelivery}</div>
      </div>
      <div className={styles.addressContainer} onClick={toggleOpen}>
        <div className={styles.addressInfo}>
          <Tooltip content={deliveryAddress} className={styles.tooltip}>
            {deliveryAddress}
          </Tooltip>
        </div>
        <CaretIcon />
      </div>
      {open ? addressComponent : null}
    </div>
  )
}

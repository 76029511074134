import React from 'react'

export default function ContactIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.025 10.7167L11.9083 10.475C11.4 10.4167 10.9 10.5917 10.5416 10.95L9.00831 12.4834C6.64997 11.2834 4.71664 9.35836 3.51664 6.9917L5.05831 5.45003C5.41664 5.09169 5.59164 4.5917 5.53331 4.08336L5.29164 1.98336C5.19164 1.1417 4.48331 0.508362 3.63331 0.508362H2.19164C1.24997 0.508362 0.46664 1.2917 0.524973 2.23336C0.96664 9.35003 6.65831 15.0334 13.7666 15.475C14.7083 15.5334 15.4916 14.75 15.4916 13.8084V12.3667C15.5 11.525 14.8666 10.8167 14.025 10.7167Z"
        fill="var(--navbar-text-color)"
      />
    </svg>
  )
}

import React, {useState} from 'react'
import {useLoginViaAuthWindow} from '@helpers/auth/loginViaAuth'
import useWebsiteLoyaltyConfig from '@page-components/CheckoutV3/hooks/useWebsiteLoyaltyConfig.main'
import UserMenu from '@page-components/Layout/Website/Layouts/Default/Navbar/Menu'

import WebsiteCoins from '../../Navbar/Menu/WebsiteCoins'
import AccountIcon from '../Icons/AccountIcon'

import styles from './styles.module.css'

export default function Account(props) {
  const {me, website, navbar = {}} = props
  const loginViaAuth = useLoginViaAuthWindow('navbarcpg')
  const [open, setOpen] = useState(false)
  const {loyaltyProgramConfig} = useWebsiteLoyaltyConfig(website._id)

  const login = () => {
    loginViaAuth()
  }

  const handleClick = () => {
    if (me) {
      toggleOpen()
    } else {
      login()
    }
  }

  const toggleOpen = () => {
    setOpen(e => !e)
  }

  return (
    <div className={styles.accountInfo}>
      <div className={styles.icon}>
        <AccountIcon />
      </div>
      <div className={styles.person} onClick={handleClick}>
        {me ? (me.name ? me.name : 'Mi cuenta') : 'Iniciar Sesión'}
      </div>
      <WebsiteCoins loyaltyProgramConfig={loyaltyProgramConfig} me={me} />
      <div className={styles.options}>
        <UserMenu
          pointsConfiguration={website.pointsConfiguration}
          buttonColor={navbar.buttonColor || '#252525'}
          open={open}
          toggleOpen={toggleOpen}
          closeMenu={() => setOpen(false)}
          onlyOptions
        />
      </div>
    </div>
  )
}

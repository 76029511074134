import React from 'react'
import ClientSuspense from '@components/ClientSuspense'
import Image from '@components/Image'
import isServerSide from '@helpers/misc/isServerSide'
import useWebsiteLayout from '@hooks/useWebsiteLayout'
import useInitialData from '@page-components/Layout/useInitialData'
import Links from '@page-components/Layout/Website/Layouts/Default/Navbar/Links'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'
import Link from 'next/link'

import TopRibbon from './TopRibbon/index'
import Account from './Account'
import Cart from './Cart'
import Delivery from './Delivery'
import SearchBox from './SearchBox'
import useCPGCategories from './useCPGCategories.main'

import styles from './styles.module.css'

export default function NavbarCPG() {
  const {website} = useInitialData()
  const layout = useWebsiteLayout(website)
  const categories = useCPGCategories()

  const {me} = useApolloQuery({
    query: gql`
      query getUserInfoNavbarCPGs($websiteId: ID) {
        me {
          _id
          name
          email
          roles
          roleInWebsite(websiteId: $websiteId) {
            _id
            role
          }
          pointsInWebsite(websiteId: $websiteId)
          websiteCoinsBalance(websiteId: $websiteId)
        }
      }
    `,
    variables: {websiteId: website._id},
    partial: true,
    omit: isServerSide()
  })

  const navbar = (website && website.navbarConfiguration) || {
    backgroundColor: '#fff',
    textColor: '#111',
    links: [],
    logo: null
  }

  const finalLogo = navbar.logo || website.logo
  return (
    <header className={styles.header}>
      <TopRibbon />
      <div className={styles.headerActions}>
        <div className={styles.logo}>
          <Link href="/">
            <div className="h-[72px] w-48 relative">
              <Image priority layout="fill" objectFit="contain" src={finalLogo.url} alt="Logo" />
            </div>
          </Link>
        </div>
        <SearchBox layout={layout} />
        <ClientSuspense fallback={null}>
          <Delivery />
        </ClientSuspense>
        <ClientSuspense fallback={null}>
          <Account me={me} website={website} />
        </ClientSuspense>
        <ClientSuspense fallback={null}>
          <Cart layout={layout} />
        </ClientSuspense>
      </div>
      <ClientSuspense fallback={null}>
        <Links links={navbar.links} categories={categories} cpg />
      </ClientSuspense>
    </header>
  )
}

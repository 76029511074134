import {GetMyOrderPreferencesQuery} from '@data/__generated__/types.main'
import {useMyOrderPreferences} from '@data/queries/preferences/getMyOrderPreferences.main'

export interface UseOrderPreferencesWebsite {
  _id?: string
  defaultMenuId?: string
}

export interface UseOrderPreferencesResult {
  loading: boolean
  preferences: Partial<GetMyOrderPreferencesQuery['preferences']> | null
}

/**
 * NOTE: This must be SSR Compatible (i.e. it must not suspend nor return user data)
 */
export default function useMyOrderPreferencesWithDefault(
  website: UseOrderPreferencesWebsite
): UseOrderPreferencesResult {
  const {preferences: queriedPrefs, networkStatus} = useMyOrderPreferences(website._id)

  const preferences = queriedPrefs ? queriedPrefs : {menuId: website.defaultMenuId}

  return {
    preferences,
    loading: networkStatus <= 2 || !website
  }
}

import React, {useEffect, useRef, useState} from 'react'
import SafeSuspense from '@components/SafeSuspense'
import useMyOrderPreferencesWithDefault from '@hooks/useMyOrderPreferencesWithDefault'
import useInitialData from '@page-components/Layout/useInitialData'
import Content from '@page-components/Order/Cart/Content'

import CartIcon from '../Icons/CartIcon'

import styles from './styles.module.css'

export default function Cart(props) {
  const {website} = useInitialData()
  const {preferences} = useMyOrderPreferencesWithDefault(website)
  const {layout} = props
  const [open, setOpen] = useState(false)
  const ref = useRef(null)

  const toggleOpen = () => {
    setOpen(e => !e)
  }

  const closeOpen = () => {
    setOpen(false)
  }
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeOpen()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  if (!preferences?.cart) return null

  return (
    <div className={styles.cartContainer} ref={ref}>
      <div className={styles.icon} onClick={toggleOpen}>
        <CartIcon />
      </div>
      <div className={styles.numberOfProducts} onClick={toggleOpen}>
        {preferences.cart && preferences.cart.items ? preferences.cart.items.length : 0}
      </div>

      <div
        className={styles.container}
        style={{transform: open ? 'translateX(0)' : 'translateX(-100vw)'}}>
        <div className={styles.title}>Mi pedido</div>
        {preferences.cart ? (
          <SafeSuspense fallback={null}>
            <Content />
          </SafeSuspense>
        ) : (
          <div className={styles.loadingContainer}>
            <div className={styles.loading}></div>
          </div>
        )}
      </div>
    </div>
  )
}
